body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: #F5F5F5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.capitalize { text-transform: capitalize }
.uppercase { text-transform: uppercase }
.no-textwrap { text-wrap: nowrap }

.authentication-container {
  display: flex;
  justify-content: center;
}

p.alert {
  border-radius: 6px;
  margin: 12px 0;
  width: 100%;
  font-size: 12px;
  padding: 12px;
  box-sizing: border-box;
  text-align: justify;
}

p.alert.alert-info {
  border: 1px solid #b8daff;
  color: #004085;
  background-color: #e4f1fe;
}

p.alert.alert-warning {
  border: 1px solid #ffecb5;
  color: #664d03;
  background-color: #fff3cd;
}

.authentication-modal {
  margin-top: 40px;
  max-width: 360px;
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(112, 112, 112, 0.2) 0px 8px 24px;
  text-align: center;
  border-bottom: 1px solid #3156FD;
  padding: 24px 36px;
}

.authentication-modal h3 {
  color: #3e3e3e;
  margin: 0 0 8px 0;
  font-weight: 500;
}

.authentication-modal p {
  color: #929292;
  font-size: 12px;
}

.authentication-modal button {
  margin-top: 32px;
  padding: 12px 24px;
  color: white;
  border: none;
  background-color: #3156FD;
  border-radius: 6px;
  font-size: 12px;
  transition: .5 ease;
  
}

.blocked-container, .blocked-container button {
  margin-top: 36px
}

.blocked-container h3 {margin-bottom: 8px;}
.blocked-container {justify-content: center; display: flex;}

.blocked-container p {
  color: #929292;
  margin-top: 0;
  font-size: 14px;
}

.blocked-container p a { font-weight: 600; color: #3156FD; }

button:hover {
  opacity: .9;
  cursor: pointer;
}

.sidenav {
  height: 100%;
  width: 210px;       
  background-color: #175FDC;
  position: fixed;
  transition: .5s ease;
  top: 60px;
  z-index: 0;
}

.main {
  width: calc(100% - 210px);
  box-sizing: border-box;        
  background-color: whitesmoke;
  padding: 12px;
  position: absolute;
  top: 60px;
  z-index: -1;
}

.sidenav.active {
  margin-left: -210px;
}

.sidenav.active ~ .main {
  width: 100%;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

nav {
  padding: 8px;
  height: fit-content;
  background-color: white;
  position: fixed;
  width: calc(100% - 16px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

nav .nav-left {
  display: flex;
  justify-content: space-between;
}

nav .nav-right {
  display: flex;
  justify-content: space-between;
  margin-right: 8px;
  align-items: center;
}

nav .nav-right p {
  font-size: 12px;
  margin: 4px;
  text-align: right;
}

nav .nav-right div {
  margin-right: 12px;
}

nav .nav-right p:first-child {
  font-weight: 600;
  color: #3e3e3e;
}

nav .nav-right p:last-child {
  color: #3e3e3e;
}

nav .nav-right .user-manual {
 border-left: 1px solid #D9D9D9;
 border-right: 1px solid #d9d9d9;
 color: #007AFF;
 padding: 8px 16px;
 font-size: 12px;
 cursor: pointer;
 font-weight: 500;
}

.text-lighter {
  font-weight: normal !important;
}

.notify-button {
  display: flex;
  align-items: center;
  color: #175FDC;
}

.notify-button p{ margin: 0; }

.custom-button {
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 12px;
  text-wrap: nowrap;
}

.custom-button.red-outline {
  border: 1px solid firebrick;
  background-color: transparent;
  color: firebrick;
  transition: .3s ease;
  font-weight: 600;

}

.custom-button.red-outline:hover {
  background-color: firebrick;
  color: white;
}

.custom-button.red {
  border: none;
  background-color: firebrick;
  color: white;
}

.custom-button.green {
  border: none;
  background-color: green;
  color: white;
}

.custom-button.blue {
  border: none;
  background-color: #175FDC;
  color: white;
}

table tbody .link-text {
  font-weight: 600;
  color: #175FDC;
  cursor: pointer;
  /* text-decoration: underline; */
}

.custom-button.blue:disabled, 
.custom-button.green:disabled, 
.custom-button.red:disabled, 
.custom-button.red-outline:disabled,
.custom-button.red-outline:hover:disabled,
.custom-button.blue-outline:hover:disabled,
.custom-button.blue-outline:disabled {
  border: none !important;
  background-color: #dadada;
  color: #3e3e3e;
  font-weight: 500;
}

.custom-button.blue-outline {
  border: none;
  background-color: transparent;
  border: 1px solid #175FDC;
  color: #175FDC;
  width: max-content;
  transition: .3s ease;
  font-weight: 600;
}

.custom-button.blue-outline:hover {
  background-color: #175FDC;
  color: white;
}

.custom-button.grey-outline {
  border: 1px solid gray;
  background-color: transparent;
  color: grey;
  height: 34px;
  font-weight: 600;
}

.nav-item .material-symbols-rounded {
  color: white;
  margin-right: 8px;
}

.menu_btn .material-symbols-rounded {
  color: #175FDC;
  background-color: whitesmoke;
  border-radius: 6px;
  padding: 4px;
  font-size: 28px;
}

.menu_btn {
  margin-top: 4px;
  margin-right: 12px;
}

ul {
  list-style: none;
}

.sidenav ul {
  padding: 0;
  margin: 8px 0;
}

li.nav-item {
  display: flex;
  font-size: 12px;
  color: white;
  align-items: center;
  padding: 12px;
  height: 24px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 8px;
}

li.nav-item:hover, li.nav-item.active, li.nav-item:hover .material-symbols-rounded, li.nav-item.active .material-symbols-rounded {
  color: #175FDC;
  background-color: white;
}

.main .page_title {
  color: #3e3e3e;
  margin: 14px 0;
  font-weight: 500;
  text-align: left;
}

div .main-inner {
  background-color: white;
  height: fit-content;
  width: 100%;
  border-radius: 6px;
  box-shadow: rgba(107, 107, 107, 0.2) 0px 8px 24px;  
}

.main-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px; /* Adds spacing between cards */
  margin-bottom: 8px;
}

.main-top .card {
  background-color: white;
  border-radius: 6px;
  flex: 1;
  height: 64px;
  padding: 12px;
  text-align: left;
  cursor: pointer;
  transition: .3s ease-in;
}

.main-top .card:nth-child(1):hover {
  background-color: #bbd4ff;
}

.main-top .card:nth-child(2):hover {
  background-color: #ffd9a3;
}

.main-top .card:nth-child(3):hover {
  background-color: #a2dcad;
}

.main-top .card:nth-child(4):hover {
  background-color: #ffcab3;
}

/* .main-top .card div{
  display: flex;
  justify-content: space-between;
} */

.main-top .card img {
  width: 52px;
  height: px;
}

.main-top .card .card-right {
  text-align: right;
}

.main-top .card .card-right h3 { margin-bottom: 12px !important }

.main-top .card .card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-top .card h5 {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}

.main-top .card h3 {
  font-size: 24px;
  font-weight: 600;
}

.main-top .card:nth-child(1) h3 {
  color: #175FDC;
  margin: 0;
}

.main-top .card:nth-child(2) h3{
  color: #FEA628;
  margin: 0;
}

.main-top .card:nth-child(3) h3{
  color: #27A445;
  margin: 0;
}

.main-top .card:nth-child(4) h3{
  color: #EE6F39;
  margin: 0;
}

div .main-bottom {
  height: fit-content;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  height: 300px;
  margin-top: 8px;
  box-shadow: rgba(107, 107, 107, 0.2) 0px 8px 24px;  

}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

.table-container {
  overflow-x: auto;
  min-height: 700px;
  background-color: white;
}

.table-container.custom {height: 650px !important;}

table tbody tr:nth-child(even), table tbody tr:hover {
 background-color: #f5f9ff;
}

table tbody tr

td, th {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid rgb(241, 241, 241);
}

th {
  background-color: #efefef;
  font-weight: 500;
  font-size: 12.5px;
}

.main .main-inner-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  flex-wrap: wrap;
}

.main .main-inner-top div.table-title {font-size: 14px; font-weight: 500;}

.left-item {
  flex: 0 0 auto; /* Fixed size, won't grow or shrink */
}

.right-item {
  margin-left: auto; /* Push it to the right */
}

table td.action {
  align-items: center;
}

table .actions {
  display: flex;
  align-items: center;
}

table .actions button {
  margin-right: 12px;
}

.main select {
    border: 1px solid rgb(192, 192, 192);
    padding: 8px;
    border-radius: 6px;
    font-size: 12px;
    min-width: 140px;
}

.main .search-container, .main input[type="date"] {
  border: 1px solid rgb(192, 192, 192);
  padding: 8px;
  border-radius: 6px;
  margin-right: 8px;
  text-align: left;
  box-sizing: border-box;
  display: inherit;
}

/* .main input[type="date"] {margin-right: 0} */

.main .search-container span {
  color: rgb(150, 150, 150);
  font-size: 18px;
  margin: 0;
}

.main input {
  border: none;
  padding: 0 8px;  
}

input:focus, textarea:focus {
  outline: none;  
}

textarea {
  width: 100%;
  border-radius: 6px;
  height: 80px;
  box-sizing: border-box;
  padding: 8px;
  border-color: #929292;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);  
}

.modal.modal-2 {
  width: 280px;
  padding-top: 8px;
  height: fit-content;
  background: url("../src/assets/images/modal-back.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: white;
}

.modal.modal-3 {
  width: 320px;
}

.modal .role-list h4 { color: #007AFF; font-weight: 500; text-align: center; margin-top: 52px; margin-bottom: 8px;}
.modal .role-list p { font-size: 12px; color: #3e3e3e; margin-top: 0; text-align: center; font-weight: 400; margin-bottom: 24px;}

.modal .role-list .role-item { 
  background-color: #ededed;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 8px;
}

.modal .role-list .role-item:hover { background-color: #e4e4e4; }

.modal .role-list .role-item span {font-size: 12px; margin-left: 6px;}

.modal .role-list input {width: 16px !important; margin: 0 !important; cursor: pointer;}

.modal .role-list input[type="radio"]:checked + span{ 
  color: #007AFF; font-weight: 500; }

.modal.modal-sm {
  width: 120px;
  align-items: center;
  text-align: center;
}

.modal.modal-4 {
  width: 400px;
}

.modal.modal-5 {
  width: 480px;
}

.modal.modal-6 { width: 500px; min-height: 270px;}

.modal .modal-header {
  display: flex;
  align-items: center;
  font-size: .9em;
  justify-content: space-between;
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 12px;
  margin-bottom: 8px;
}

.modal h3 {
  font-weight: 500;
  margin: 0;
  font-size: 1em;
}

.modal span {
  font-size: 12px;
}

.modal .modal-body.html-content {
  height: 340px;
  overflow-y: auto;
}

.modal .chat-container {
  height: 270px;
  overflow-y: auto;
}

.modal .modal-body.status-progress {
  height: 440px;
  overflow-y: auto;
}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.w-50 {
  width: 50%;
}

.mr-2 {
  margin-right: 8px;
}

.ml-2 {
  margin-left: 8px;
}

.modal input,.modal select {
  border: 1px solid #D9D9D9;
  padding: 10px 12px;
  border-radius: 6px;
  width: 100%;
  margin-top: 8px;
  /* margin-bottom: 8px; */
  box-sizing: border-box;
}

#test-input {
  color: red !important
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  font-size: 13px !important;
  border: none !important;
  margin: 0;
}

.modal #autocomplete {
  padding: 16px 8px;
}

.modal input[type="checkbox"], .modal input[type="radio"] { width: 24px; }

.modal label { font-size: 13px; font-weight: 500; color: #1e1e1e; }

select {
  padding: 10px 8px !important;
  outline: none;
}

.or-text {
  display: flex;
  flex-direction: row;
  margin: 0;
  /* font-size: 12px; */
  color: #3e3e3e;
}

.or-text:before,
.or-text:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #D9D9D9;
  margin: 12px;
}

.modal .modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  gap: 12px;
}

.modal .modal-footer.bt-1 {
  border-top: 1px solid #D9D9D9;
  padding-top: 12px;
}

.modal .tab {
  justify-content: space-between;
  margin: 8px 0;
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 12px;
}

.modal .tab-item {
  padding: 10px;
  border-radius: 6px;
  background-color: white;
  border: 1px solid #175FDC;
  font-size: 12px;
  color: #175FDC;
  text-align: center;
  width: 100%;
}

.modal .tab-item.active {
  background-color: #175FDC;
  color: white;
}

.error {
  font-size: 12px;
  color: red;
  margin-top: 6px;
}

span.close {
  cursor: pointer;
  font-size: 20px !important
}

span.errorX {
  font-size: 12px;
  color: red;
  margin-top: 4px;
  margin-right: 4px;
}

span.sm-text { font-size: 10px; }

.small-circle {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: white;
  margin: 0 8px;
}

.nav-item:hover .small-circle, .nav-item.active .small-circle {
  background-color: #175FDC;
}

span.small-text {font-size: 11px; color: grey}


table .last-column {
  width: 120px;
}

.pagination-group {
  display: flex;
  justify-content: space-between;
  width: 100%
}

.pagination-group div:first-child {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pagination-container {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;  
}

.pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pagination .page-item{
  padding: 8px 12px;
  margin-left: 8px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #175FDC;
  background-color: white;
  color: #175FDC;
}

.pagination-group p.page-num{
  padding: 8px;
  font-size: 14px;
  margin: 0;
}

.pagination .page-item:hover{  
  background-color: whitesmoke;
}

.pagination .page-item.active{  
  background-color: #175FDC;
  color: white;
}

.pagination .page-item:disabled{  
  border: 1px solid rgb(160, 160, 160);
  color: rgb(160, 160, 160);
}

.pagination-group select {
  width: fit-content;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  margin-right: 12px;
  padding-left: 8px;
  height: 38px;
}

.detail-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
}

.detail-table-section:last-child thead tr th:last-child {
  width: 200px;
}

.detail-top-section p {
  font-size: 13px;
  color: #175FDC;
  align-items: center;
  display: flex;
  font-weight: 500;
  cursor: pointer;
  margin: 8px 0;
}

.detail-top-section span {
  margin-right: 8px;
  font-size: 18px;
}

.detail-table-section.last-section thead tr th:nth-child(1) { width: 300px; }

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}

.table-header p {
  color: #3e3e3e;
  font-weight: 500;
  font-size: 15px;
}

.detail-table-section table td .view-link {
  color: #175FDC;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.table-header a, .table-header button {
  text-decoration: underline;
  font-size: 13px;
  color: #175FDC;
  font-weight: 500;
  border: none; background-color: transparent;
}

.table-header-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 8px 12px;
}

.table-header-2 div {
  display: flex;
  justify-content: space-between; 
}

.table-header-2 p {
  font-size: 13px;
}

table tbody tr td.status-column select {
  border-radius: 6px;
  padding: 8px 16px;
  width: 292px;
}

table tbody tr td.status-column {
  /* display: block; */
  text-align: right;
  width: 160px;
}

table tbody tr td.status-column button {
  margin-top: 8px;
}

table tbody tr td.pending { background-color: #ffe8c7}
table tbody tr td.ongoing { background-color: #d7eaff}
table tbody tr td.closed { background-color: #bfe3c6}

.detail-table-section {
  padding: 0 12px;
  overflow-x: auto;  
}

.detail-table-section.custom {height: 400px;}


.detail-table-section.align-top table td  {
  vertical-align: top;
}

.mt-1 {
  margin-top: 8px !important;
}

.mt-2 {
  margin-top: 16px !important;
}

.mb-2 {
  margin-bottom: 16px;
}

.ml-1 {
  margin-left: 8px;
}

.mr-1 {
  margin-right: 8px;
}

.d-flx {
  display: flex;
  /* align-items: top; */
}

.blue-text {
  color: #175FDC;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
  margin-right: 16px;
  text-wrap: nowrap;
  font-size: 12px;
  border: 0;
  background-color: transparent;
}

.blue-text.disabled, .blue-text:disabled {
  color: #929292;
}

.blue-text:hover { text-decoration: underline; }

span.chat-button {
  color: #175FDC;
  cursor: pointer;
}

span.chat-button.disabled {
  color: #929292;
  cursor: default;
}

span.status {
  text-wrap: nowrap;
  padding: 4px 8px;
  border-radius: 24px;
}

span.status.pending {
  color: #E38600;
  background-color: #FFEFD9;  
}

span.status.success, span.status.approved {
  color: #267739;
  background-color: #d2e6d6;
}

span.status.danger, span.status.rejected {
  color: #C00000;
  background-color: #FFDDDD;
}

.red {color: red}

span.status.info {
  color: #175FDC;
  background-color: #d8e6ff;
}

p.description-area {
  height: 300px;
  width: 100%;
  overflow-y: auto;
  font-size: 12px;
  text-align: justify;
}

.modal .chat-table {
  overflow-x: auto;
  height: 200px;
}

.modal .chat-dialog {  
  border-radius: 8px;
  font-size: 12px;
  padding: 6px 12px;
  margin-bottom: 12px;
  width: 50%;
}

.modal .chat-dialog .inner {  
  display: flex;
  justify-content: space-between;
}

.modal .chat-dialog.left .inner .link { color: #007AFF; text-decoration: underline; cursor: pointer; margin: 4px 0; text-wrap: nowrap;}
.modal .chat-dialog.right .inner .link { color: white; text-decoration: underline; cursor: pointer; margin: 4px 0; text-wrap: nowrap}

.modal .chat-dialog.left { background-color: #F2F2F7; border-top-left-radius: 2px; float: left;}
.modal .chat-dialog.right { background-color: #007AFF; border-bottom-right-radius: 2px; color: white; float: right;}


.modal .chat-dialog.left .date {
  font-size: 11px;
  color: #3e3e3e;
  text-align: right;
  margin: 4px 0;
}

.modal .chat-dialog.right .date {
  font-size: 11px;
  color: white;
  text-align: right;
  margin: 4px 0;
}

.modal .chat-dialog .title {
  font-weight: 500;
  margin: 4px 0;
}

.modal .investigators-table {
  overflow-x: auto;
  height: 240px;
}

.modal .select-group input {
  height: 39px !important;
}

.modal .investigator-table-header {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin: 8px 0;
  border-bottom: 1px solid #D9D9D9;
}

.css-1hv8oq8-MuiStepLabel-label {
  font-size: 12px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  height: 18px !important;
}

.modal .select-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
}

.modal .select-group div {
  flex: 1;
}

.loader {margin-bottom: 24px}
.loader img { width: 80px }
.loader img.sm { width: 60px }
.loader p { color: grey; margin: 0; font-size: 12px }
td.no-records { padding: 12px 0; width: 124px }

/* Style The Dropdown Button */
.dropbtn {
  padding: 6px 4px 6px 12px;
  color: white;
  border: 1px solid #175FDC;
  background-color: transparent;
  color: #175FDC;
  border-radius: 6px;
  font-size: 12px;
  transition: .5 ease;
  vertical-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 60px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content button {
  color: black;
  padding: 12px;
  display: block;
  border: none;
  width: 100%;
  background-color: transparent;
  font-size: 12px;
  text-align: left;
  text-wrap: nowrap;
  /* border-bottom: 1px solid #d9d9d9; */
}

/* Change color of dropdown links on hover */
.dropdown-content button:hover {background-color: #007AFF; color: white}
.dropdown-content button:disabled {background-color: whitesmoke; color: grey}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #007AFF;
  color: white
}






/* TABLET VIEW */
@media screen and (max-width: 768px){ 
  .modal .modal-body.custom {
    overflow-y: auto;
    height: 320px
  }

  .pagination-container{
    display: block;
  }

  .pagination .page-item {
    margin-right: 8px;
    margin-left: 0;
  }

  .modal .investigators-table {
    height: 340px;
  }

  .modal {
    overflow-y: auto;
  }

  .table-container {
    height: 750px !important;
  }
}

@media only screen and (min-width: 500px) {
  .menu_btn {
    display: none;      
  }

  .menu_btn:focus {
    background-color: whitesmoke;
  }

  .main {
    margin-left: 210px;
  }

  .sidenav.active {
    margin-left: 0;
  }  
}

/* MOBILE VIEW */
@media only screen and (max-width: 500px) {
  .main {
      width: 100%;
  }

  nav .nav-right p {
    text-align: left;
  }

  nav .nav-left, nav .nav-right{
    flex: 1 50%;
    padding: 6px 0;
    
  }

  nav .nav-left{
    border-bottom: 1px solid #d8e6ff;
  }

  .sidenav, .main {
    top: 127px;
  }

  .actions button {
    width: 100%;
  }

  .main-inner {    
    overflow-y: auto;
  }  

  .pagination-container {
    position: unset;
    width: 100%;
  }
  
  .pagination-group{
    flex-direction: column;
  }

  .pagination {
    margin-top: 8px;
  }

  .modal {
    width: 350px !important;
  }

  table td.actions:last-child {
    display: flex;
    flex-wrap: wrap;
  }  

  .modal .select-group div {
    flex: 50%;
  }

  .modal .select-group div:last-child {
    margin-top: 8px;
  }

  .right-item {
    margin-left: 0;
    margin-top: 8px
  }

  .main-top .card img {
    width: 28px;
    height: 28px;
  }
}

@media only screen and (max-width: 1274px) {
  .actions button {
    margin: 4px 0;
  }
}

@media only screen and (max-width: 1100px) {
  .main-top .card {
    flex: 40%;
  }
}